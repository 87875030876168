// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-news-component-news-template-tsx": () => import("./../../../src/components/NewsComponent/NewsTemplate.tsx" /* webpackChunkName: "component---src-components-news-component-news-template-tsx" */),
  "component---src-pages-archiv-en-tsx": () => import("./../../../src/pages/archiv.en.tsx" /* webpackChunkName: "component---src-pages-archiv-en-tsx" */),
  "component---src-pages-archiv-tsx": () => import("./../../../src/pages/archiv.tsx" /* webpackChunkName: "component---src-pages-archiv-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-derby-en-tsx": () => import("./../../../src/pages/derby.en.tsx" /* webpackChunkName: "component---src-pages-derby-en-tsx" */),
  "component---src-pages-derby-tsx": () => import("./../../../src/pages/derby.tsx" /* webpackChunkName: "component---src-pages-derby-tsx" */),
  "component---src-pages-impressum-en-tsx": () => import("./../../../src/pages/impressum.en.tsx" /* webpackChunkName: "component---src-pages-impressum-en-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-en-tsx": () => import("./../../../src/pages/news.en.tsx" /* webpackChunkName: "component---src-pages-news-en-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-presse-en-tsx": () => import("./../../../src/pages/presse.en.tsx" /* webpackChunkName: "component---src-pages-presse-en-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-pages-team-en-tsx": () => import("./../../../src/pages/team.en.tsx" /* webpackChunkName: "component---src-pages-team-en-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

